import {
 EXPIRE_NAME
} from './config';

export default () => {
  const date = new Date();
  const expireTime = localStorage.getItem(EXPIRE_NAME);
  if (date.getTime() > expireTime) {
    return true;
  } else {
    return false;
  }
}
