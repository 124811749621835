import React from "react";
import Voice from "../Voice2";

function NormalIssue(props) {
  const { item } = props;
  return (
    <h3 className="title">
      {item.num + "、" + item.issue}
      {(() => {
        let text = "";
        text += `${item.num},${item.issue}`;
        item.items.forEach(radioItem => {
          text += `.${radioItem.iNum},${radioItem.item}`;
        });
        return <Voice text={text} />;
      })()}
    </h3>
  );
}

export default NormalIssue;
