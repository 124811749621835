import axios from "axios";
import { message, Modal } from "antd";
import { TOKEN_NAME } from "../api/auth/config";
import logout from "./auth/logout";

export const ERR_OK = 0;

export const BASEURL = "http://106.13.43.41:8080/renren-api";
export const IMG_BASEURL = process.env.NODE_ENV === 'development' ? "http://106.13.43.41:8080/renren-api" : '';
// export const IMG_BASEURL = "http://106.13.43.41:8080/renren-api";
export const DOWNLOAD_PATH =  process.env.NODE_ENV === 'development' ? "http://106.13.43.41:8080/renren-api/file/" : '/file/';
// export const DOWNLOAD_PATH =  "http://106.13.43.41:8080/renren-api/file/";

const myAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? `${BASEURL}/api` : "/api",
  // baseURL: `${BASEURL}/api`,
  method: "POST"
});

myAxios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem(TOKEN_NAME);

    config.headers = {
      ...config.headers,
      token: token || ""
    };

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

myAxios.interceptors.response.use(
  function(response) {
    const { result, msg } = response.data;
    if (result === ERR_OK) {
      return response;
    } else {
      console.log("result 错误");
      message.error(msg);
      return Promise.reject(response);
    }
  },
  function(error) {
    if (error.message === "Network Error") {
      Modal.info({
        title: "您的登录信息已过期，请重新登录！",
        onOk: () => {
          logout();
          window.location = "/login";
        }
      })
    } else {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
);

const api = {
  /* 登录 */
  login: ({ phone, password, role, hospitalName }) => {
    return myAxios({
      url: "/login",
      data: {
        mobile: phone,
        password,
        role,
        hospitalName
      }
    });
  },
  /* 获取用户信息 */
  getUserInfo: () => {
    return myAxios({
      method: "GET",
      url: "/userInfo"
    });
  },
  /************ 档案/患者 管理 ************/
  /* 患者列表 */
  getPatientList: ({ pageIndex, pageSize, pname }) => {
    let params = {
      page: pageIndex
    };

    if (pname) {
      params.pname = pname;
    }

    return myAxios({
      url: "/patient/list",
      params,
      data: {
        pageSize
      }
    });
  },
  /* 添加患者 */
  addPatient: ({ birth, degree, job, nation, pname, sex, zynum, marry }) => {
    return myAxios({
      url: "/patient/save",
      data: {
        id: 0, // 冗余字段
        birth,
        degree,
        job,
        nation,
        pname,
        sex,
        zynum,
        marry,
        uploadTime: new Date().toISOString()
      }
    });
  },
  /* 编辑患者信息 */
  editPatient: ({
    id,
    birth,
    degree,
    job,
    nation,
    pname,
    sex,
    zynum,
    marry
  }) => {
    return myAxios({
      url: "/patient/update",
      data: {
        id,
        birth,
        degree,
        job,
        nation,
        pname,
        sex,
        zynum,
        marry
      }
    });
  },
  /* 删除患者 */
  deletePatient: ids => {
    return myAxios({
      url: "/patient/delete",
      data: ids
    });
  },
  /************ 任务管理 ************/
  /* 任务列表 */
  getTaskList: ({ pageIndex, pageSize, pname }) => {
    let params;
    if (pname) {
      params = {
        page: pageIndex,
        pname
      };
    } else {
      params = {
        page: pageIndex
      }
    }
    return myAxios({
      url: "/task/list",
      params,
      data: {
        pageSize
      }
    });
  },
  /* 任务详情 */
  getTaskInfo: ({ id }) => {
    return myAxios({
      url: `/task/info/${id}`
    });
  },
  /* 添加任务 */
  addTask: ({ examGroupId, examId, examIds, patientId }) => {
    return myAxios({
      url: "/task/save",
      data: {
        createTime: new Date().toISOString(),
        examGroupId,
        examId,
        examIds,
        patientId,
        state: 0,
        // equipment: EQUIPMENT
        uploadTime: new Date().toISOString()
      }
    });
  },
  /* 更新任务 */
  updateTask: ({ userId, mobile, password, role, username }) => {
    return myAxios({
      url: "/task/update",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: userId,
        username
      }
    });
  },
  /* 删除任务 */
  deleteTask: ({ taskIds }) => {
    return myAxios({
      url: "/task/delete",
      data: taskIds
    });
  },
  /* 获取所有测试端列表 */
  getAllTestClientList: () => {
    return myAxios({
      url: "/user/listTest/"
    });
  },
  /************ 任务量表管理 ************/
  /* 获取任务量表信息 */
  findTaskExamById(id) {
    return myAxios({
      url: `/taskExam/info/${id}`
    })
  },
  /* 更新任务 */
  updateTaskExam: ({ id, startTime, endTime, state, result }) => {
    const data = {
      id
    };
    if (startTime) data.startTime = startTime;
    if (endTime) data.endTime = endTime;
    if (state) data.state = state;
    if (result) data.result = result;
    return myAxios({
      url: "/taskExam/update",
      data
    });
  },
  /* 计算分数 */
  countPoint: ({ id }) => {
    return myAxios({
      url: `/taskExam/result/${id}`
    });
  },
  /* 分派任务到测试端 */
  dispatchTaskToTestClient: ({ taskCode, userCode }) => {
    return myAxios({
      url: `/task/setPermissions`,
      params: {
        taskCode
      },
      data: userCode
    });
  },
  /************ 用户管理 ************/
  /* 用户列表 */
  getUserList: ({ pageIndex }) => {
    return myAxios({
      url: "/user/list",
      params: {
        page: pageIndex
      },
      data: {}
    });
  },
  /* 添加用户 */
  addUser: ({ mobile, password, role, username, hospitalName }) => {
    return myAxios({
      url: "/register",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: 0,
        username,
        hospitalName
      }
    });
  },
  /* 更新用户 */
  updateUser: ({ userId, mobile, password, role, username, hospitalName }) => {
    return myAxios({
      url: "/user/update",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: userId,
        username,
        hospitalName
      }
    });
  },
  /* 删除用户 */
  deleteUser: ({ userId }) => {
    return myAxios({
      url: "/user/delete",
      data: [userId]
    });
  },
  /************ 量表管理 ************/
  /* 量表列表 */
  getExamList: ({ pageIndex, pageSize, bname }) => {
    let params = {
      page: pageIndex
    };
    if (bname) {
      params.bname = bname;
    }
    return myAxios({
      url: "/examList/list",
      params,
      data: {
        pageSize
      }
    });
  },
  /* 全部量表列表 */
  getExamAllList: ({ pageIndex, pageSize }) => {
    return myAxios({
      url: "/examList/listAll/",
      params: {
        page: pageIndex
      },
      data: {
        pageSize
      }
    });
  },
  /* 获取单个量表的信息 */
  getExam: (id) => {
    return myAxios({
      url: `/examList/info/${id}`
    });
  },
  /* 添加量表 */
  addExam: ({ mobile, password, role, username }) => {
    return myAxios({
      url: "/examList/save",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: 0,
        username
      }
    });
  },
  /* 更新量表 */
  updateExam: ({ userId, mobile, password, role, username }) => {
    return myAxios({
      url: "/examList/update",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: userId,
        username
      }
    });
  },
  /* 删除量表 */
  deleteExam: ({ examId }) => {
    return myAxios({
      url: "/examList/delete",
      data: [examId]
    });
  },
  /* 给量表添加分类 */
  addCateForExam: ({
    examId,
    cateId
  }) => {
    return myAxios({
      url: "/examType/save",
      data: {
        examId,
        typeId: cateId
      }
    })
  },
  /* 给量表删除分类 */
  deleteCateForExam: ({
    examId,
    cateId
  }) => {
    return myAxios({
      url: "/examType/dList/",
      params: {
        examId,
        typeId: cateId
      }
    })
  },
  /************ 量表组管理 ************/
  /* 量表组列表 */
  getExamGroupList: ({ pageIndex, pageSize }) => {
    return myAxios({
      url: "/examGroup/list",
      params: {
        page: pageIndex
      },
      data: {
        pageSize
      }
    });
  },
  /* 添加量表组 */
  addExamGroup: ({ mobile, password, role, username }) => {
    return myAxios({
      url: "/examGroup/save",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: 0,
        username
      }
    });
  },
  /* 更新量表组 */
  updateExamGroup: ({ userId, mobile, password, role, username }) => {
    return myAxios({
      url: "/examGroup/update",
      data: {
        createTime: new Date().toISOString(),
        mobile,
        password,
        role,
        userId: userId,
        username
      }
    });
  },
  /* 删除量表组 */
  deleteExamGroup: ({ userId }) => {
    return myAxios({
      url: "/examGroup/delete",
      data: [userId]
    });
  },
  /************ 类型管理 ************/
  /* 类型列表 */
  getCateList: ({ pageIndex, pageSize }) => {
    return myAxios({
      url: "/type/list",
      params: {
        page: pageIndex
      },
      data: {
        pageSize
      }
    });
  },
  /* 添加类型 */
  addCate: ({ name }) => {
    return myAxios({
      url: "/type/save",
      data: {
        tyname: name
      }
    });
  },
  /* 更新类型 */
  updateCate: ({ id, name }) => {
    return myAxios({
      url: "/type/update",
      data: {
        id,
        tyname: name
      }
    });
  },
  /* 删除类型 */
  deleteCate: ({ cateId }) => {
    return myAxios({
      url: "/type/delete",
      data: [cateId]
    });
  },
  /*********** 备份相关接口 ***********/
  /* 备份接口 */
  backUpData: () => {
    return myAxios({
      url: "/tongBu/listAll/"
    })
  },
  /* 同步（上传）数据接口 */
  uploadBackup: (formData) => {
    return myAxios({
      url: "/tongBu/save",
      data: formData
    })
  }
};

export default api;
