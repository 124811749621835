import React from "react";
import { connect } from "react-redux";
import { Icon } from "antd";
import { setVoiceState } from "../../store/common/actionCreators";
import "./Voice2.less";

class Voice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      state: null
    };
  }

  play = e => {
    e.preventDefault();
    e.stopPropagation();
    window.speechSynthesis.cancel();

    setTimeout(() => {
      let utterThis = new window.SpeechSynthesisUtterance();
      utterThis.text = this.props.text;
      utterThis.lang = "zh-cn";
      this.utterThis = utterThis;
      utterThis.onstart = () => {
        console.log("start");
        this.setState({
          voiceState: "playing"
        });
      };
      utterThis.onstop = () => {
        console.log("onstop");
        this.setState({
          voiceState: null
        });
        utterThis.onstart = null;
        utterThis.onpause = null;
        utterThis.onstop = null;
        utterThis.onend = null;
      };
      utterThis.onpause = () => {
        console.log("onpause");
        utterThis.onstart = null;
        utterThis.onpause = null;
        utterThis.onstop = null;
        utterThis.onend = null;
      };
      utterThis.onend = () => {
        console.log("onend");
        this.setState({
          voiceState: null
        });
        utterThis.onstart = null;
        utterThis.onpause = null;
        utterThis.onstop = null;
        utterThis.onend = null;
      };

      window.speechSynthesis.speak(utterThis);
    }, 300);
  };

  pause = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      {
        voiceState: null
      },
      () => {
        window.speechSynthesis.pause();
      }
    );
  };

  render() {
    const state = this.state.voiceState || this.props.voiceState;
    if (state === "pause") {
      return (
        <span
          style={this.props.style}
          className="voice-icon__wrapper"
          title="阅读该题"
          onClick={this.play}>
          <Icon type="customer-service" theme="twoTone" />
        </span>
      );
    } else {
      return (
        <span
          style={this.props.style}
          className="voice-icon__wrapper"
          title="暂停"
          onClick={this.pause}>
          {/* 暂停阅读 */}
          <Icon type="pause-circle" theme="twoTone" />
        </span>
      );
    }
  }
}

const mapStateToProps = state => ({
  voiceState: state.common.voiceState
});

const mapDispatchToProps = dispatch => ({
  setVoiceState: data => dispatch(setVoiceState(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Voice);
