import React from "react";
import LogoImg from "../assets/logo.jpg";
import LogoImgTrans from "../assets/logo-transparent.png";
import LogoImgWithText from "../assets/logoWithText.jpg";

const imgStyle = {
  boxSizing: "content-box"
};

function Logo(props) {
  const { type } = props;
  let src = "";
  if (type === "text") {
    src = LogoImgWithText;
  } else if (type === "trans") {
    src = LogoImgTrans
  } else {
    src = LogoImg;
  }
  return (
    <div>
      <img
        {...props}
        style={{ ...imgStyle, ...props.style }}
        src={src}
        alt="logo"
      />
    </div>
  );
}

export default Logo;
