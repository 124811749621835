import React from "react";
import { Spin, Skeleton } from "antd";
import http from "../../api/api";

const Loading = function() {
  return (
    <Spin tip="报告生成中...">
      <Skeleton />
    </Spin>
  );
};

const ReportObj = {};
for (let i = 0; i < 300; i++) {
  ReportObj[`Report${i}`] = React.lazy(() => import(`./Report_${i}`));
}

class ReportController extends React.Component {
  constructor(props) {
    super(props);
    const { taskId, taskExamId, examId } = props.match.params;
    this.state = {
      taskId,
      taskExamId,
      examId,
      task: null,
      taskExam: null,
      patient: null
    };
    this._getTaskInfo(taskId);
  }

  _getTaskInfo(taskId) {
    http
      .getTaskInfo({
        id: taskId
      })
      .then(res => {
        let taskExam;
        res.data.taskExams.forEach(item => {
          if (+item.taskExam.id === +this.state.taskExamId) {
            taskExam = item
          }
        })
        this.setState({
          task: res.data,
          taskExam,
          patient: res.data.patient
        });
      });
  }

  render() {
    const Report = ReportObj[`Report${this.state.examId}`];
    return (
      <React.Suspense fallback={<Loading />}>
        <Report
          patient={this.state.patient}
          taskExam={this.state.taskExam}
        />
      </React.Suspense>
    );
  }
}

export default ReportController;
