export const prefix = "_HOSPITAL";

export const TOKEN_NAME = `${prefix}_TOKEN`;

export const EXPIRE_NAME = `${prefix}_EXPIRE`; // 记录 token 的过期时间 timestamp

export const USER_INFO_NAME = `${prefix}_USERINFO`;

export const ROLES = {
  1: "管理员",
  2: "医生",
  3: "测试",
  "ADMIN": 1,
  "DOCTOR": 2,
  "PATIENT": 3
};

export const TASK_STATE = {
  0: {text:"未开始", color: '#2db7f5' },
  1: {text:"进行中", color: 'orange'},
  2: {text:"已完成", color: 'green'}
};

export const TASK_EXAM_STATE = {
  0: "未开始",
  1: "进行中",
  2: "已完成",
  "未开始": 0,
  "进行中": 1,
  "已完成": 2
}
