import React from "react";
import TestForm from "./TestForm";
import { Card, Carousel, Icon, Divider } from "antd";
import "./test.less";
import http from "../../api/api";

import examData from "./examData.4";

const { Meta } = Card;
class Test extends React.PureComponent {
  state = {
    currentTest: 0,
    taskExams: [],
    patient: {},
    taskId: 0
  };

  testSlider = React.createRef();

  changeTest = index => {
    if (this._isSliding) return;
    this._isSliding = true;
    this.setState({ currentTest: index }, () => {
      this.testSlider.current.slick.slickGoTo(index);
      setTimeout(() => {
        this._isSliding = false;
      }, 500);
    });
  };

  _getTaskInfo = () => {
    console.log(this.props, "sss");
    http.getTaskInfo({ id: Number(this.props.match.params.id) }).then(res => {
      const { taskExams, patient, task_Id: taskId } = res.data;
      this.setState({
        taskExams,
        patient,
        taskId
      });
    });
  }

  // 生命周期
  componentDidMount() {
    // 获取任务数据
    this._getTaskInfo();
  }

  render() {
    console.log('this.state', this.state);
    return (
      <div>
        <div
          style={{
            overflow: "auto",
            whiteSpace: "nowrap",
            padding: "16px 0 0"
          }}>
          {this.state.taskExams.map((item, index) => {
            console.log(item);
            //return <span key={item.exam.id}>{item.exam.bname}</span>
            return (
              <Card
                hoverable
                className={
                  this.state.currentTest === index
                    ? "test-card-item test-card-item--active"
                    : "test-card-item"
                }
                onClick={() => {
                  this.changeTest(index);
                }}
                key={index}>
                <Meta
                  title={item.exam.bname}
                  avatar={
                    null && (
                      <Icon
                        type="check-circle"
                        style={{
                          paddingTop: 5,
                          display: "inline-block",
                          color: "#1890ff"
                        }}
                      />
                    )
                  }
                />
              </Card>
            );
          })}
        </div>
        <Divider />
        <Carousel
          ref={this.testSlider}
          dots={false}
          accessibility={false}
          arrows={false}>
          {this.state.taskExams.map(item => {
            return (
              <div key={item.exam.id}>
                <TestForm
                  taskId={this.state.taskId}
                  taskExams={this.state.taskExams}
                  data={item}
                  patient={this.state.patient}
                  complete={this._getTaskInfo}
                />
              </div>
            );
          })}
        </Carousel>
          {/* <div>
            <TestForm data={examData}
                  taskId={this.state.taskId}
                  patient={this.state.patient}
                  complete={this._getTaskInfo} />
          </div> */}
      </div>
    );
  }
}

export default Test;

// match.params.id
