import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { reducer as common } from "./common";
import { reducer as patient } from "./patient";
import sagas from './sagas';

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  common,
  patient
});

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware();

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   persistedReducer /* preloadedState, */,
//   composeEnhancers(
//     applyMiddleware(sagaMiddleware)
//   )
// );
export default () => {
  let store = createStore(persistedReducer,
    composeEnhancers(
      applyMiddleware(sagaMiddleware)
    ))
  sagaMiddleware.run(sagas);
  let persistor = persistStore(store)
  return { store, persistor }
}
/* eslint-enable */
// export default store;
