import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Form, Icon, Input, Button, Checkbox, message } from "antd";
import Logo from "../components/Logo.jsx";
import { default as http } from "../api/api";
import { TOKEN_NAME, EXPIRE_NAME, ROLES } from "../api/auth/config";
import "./Login.less";
import setUserInfo from "../api/auth/setUserInfo.js";
import getUserInfo from "../api/auth/getUserInfo.js";

const startCanvas = function() {
  const CANVAS_ID = "login_bg";
  var canvas = document.getElementById(CANVAS_ID);
  var ctx = canvas.getContext("2d");
  var rgb = "255"; // 线条颜色值
  var extendDis = 5; // 可超出的画布边界
  var lineDis = 100; // 连线距离

  lineDis *= lineDis;
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  var RAF = (function() {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  // 鼠标活动时，获取鼠标坐标
  var warea = { x: null, y: null };

  window.onmousemove = function(e) {
    e = e || window.event;

    warea.x = e.clientX - canvas.offsetLeft;
    warea.y = e.clientY - canvas.offsetTop;
  };

  window.onmouseout = function(e) {
    warea.x = null;
    warea.y = null;
  };

  // 添加粒子
  // x，y为粒子坐标，xa, ya为粒子xy轴加速度，max为连线的最大距离
  var dots = [];
  for (var i = 0; i < 400; i++) {
    var x = Math.random() * (canvas.width + 2 * extendDis) - extendDis;
    var y = Math.random() * (canvas.height + 2 * extendDis) - extendDis;
    var xa = (Math.random() * 2 - 1) / 1.5;
    var ya = (Math.random() * 2 - 1) / 1.5;

    dots.push({
      x: x,
      y: y,
      xa: xa,
      ya: ya
    });
  }

  // 延迟100秒开始执行动画，如果立即执行有时位置计算会出错
  setTimeout(function() {
    animate();
  }, 100);

  // 每一帧循环的逻辑
  function animate() {
    // 如果 canvas 被删除，则动画停止
    if (!document.getElementById("login_bg")) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    bubDrawLine([warea].concat(dots));

    RAF(animate);
  }

  /**
   * 逐个对比连线
   * @param ndots
   */
  function bubDrawLine(ndots) {
    var ndot;

    dots.forEach(function(dot) {
      move(dot);

      // 循环比对粒子间的距离
      for (var i = 0; i < ndots.length; i++) {
        ndot = ndots[i];

        if (dot === ndot || ndot.x === null || ndot.y === null) continue;

        var xc = dot.x - ndot.x;
        var yc = dot.y - ndot.y;

        // 如果x轴距离或y轴距离大于max,则不计算粒子距离
        if (xc > ndot.max || yc > lineDis) continue;

        // 两个粒子之间的距离
        var dis = xc * xc + yc * yc;

        // 如果粒子距离超过max,则不做处理
        if (dis > lineDis) continue;

        // 距离比
        var ratio;

        // 如果是鼠标，则让粒子向鼠标的位置移动
        if (ndot === warea && dis < 20000) {
          dot.x -= xc * 0.01;
          dot.y -= yc * 0.01;
        }

        // 计算距离比
        ratio = (lineDis - dis) / lineDis;

        // 粒子间连线
        ctx.beginPath();
        ctx.lineWidth = ratio / 2;
        ctx.strokeStyle = "rgba(" + rgb + ", " + rgb + ", " + rgb + ", 1";
        ctx.moveTo(dot.x, dot.y);
        ctx.lineTo(ndot.x, ndot.y);
        ctx.stroke();
      }

      // 将已经计算过的粒子从数组中删除
      ndots.splice(ndots.indexOf(dot), 1);
    });
  }

  /**
   * 粒子移动
   * @param dot
   */
  function move(dot) {
    dot.x += dot.xa;
    dot.y += dot.ya;

    // 遇到边界将加速度反向
    dot.xa *= dot.x > canvas.width + extendDis || dot.x < -extendDis ? -1 : 1;
    dot.ya *= dot.y > canvas.height + extendDis || dot.y < -extendDis ? -1 : 1;

    // 绘制点
    ctx.fillStyle = "rgba(" + rgb + ", " + rgb + ", " + rgb + ", 1";
    ctx.fillRect(dot.x - 0.5, dot.y - 0.5, 1, 1);
  }
};

class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogining: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(this.props);
        console.log("Received values of form: ", values);
        const { phone, password, hospitalName } = values;
        this.setState({
          isLogining: true
        });
        http
          .login({
            phone,
            password,
            hospitalName
          })
          .then(res => {
            const { token, expire } = res.data;
            localStorage.setItem(TOKEN_NAME, token);
            localStorage.setItem(
              EXPIRE_NAME,
              new Date().getTime() + expire * 1000
            );
            this.setState({
              isLogining: false
            });

            http.getUserInfo().then(res => {
              const { user } = res.data;
              setUserInfo(user);
              const userInfo = getUserInfo();
              const { role } = userInfo;

              switch (role) {
                case `${ROLES.ADMIN}`:
                  this.props.history.push("/admin");
                  break;
                case `${ROLES.DOCTOR}`:
                  this.props.history.push("/doctor");
                  break;
                case `${ROLES.PATIENT}`:
                  this.props.history.push("/patient");
                  break;
                default:
                  message.error("登录信息 role 错误！");
              }
            });

          })
          .catch(() => {
            this.setState({
              isLogining: false
            });
          });
      }
    });
  };

  componentDidMount() {
    startCanvas();
  }

  componentWillUnmount() {
    const canvas = document.getElementById("login_bg");
    if (canvas) {
      canvas.parentElement.removeChild(canvas);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login_wrapper">
        <canvas
          id="login_bg"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div className="login_form-card">
          <div
            style={{
              border: "1px solid #eee",
              boxShadow: "0 0 8px rgba(0,0,0,.1)",
              borderRadius: "3px",
              padding: "20px 30px",
              background: "#fff"
            }}>
            {/* <h1 className="login_title" style={{marginBottom: 5}}>侯马安定医院</h1> */}
            <h1 className="login_title">心理测评管理系统（PAMS）</h1>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator("hospitalName", {
                  rules: [{ required: true, message: "请输入医院名称！" }]
                })(
                  <Input
                    prefix={
                      <Icon type="bank" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="医院名称"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入用户名！" }]
                })(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="用户名"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [{ required: true, message: "请输入密码" }]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="密码"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: true
                })(<Checkbox>下次自动登录</Checkbox>)}
                {/* eslint-disable */}
                {/* <a className="login-form-forgot" href="javascript:;">
                  忘记密码？
                </a> */}
                {/* eslint-enable */}
                <Button
                  loading={this.state.isLogining}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button">
                  登录
                </Button>
                {/* eslint-disable */}
                {/* <a href="javascript:;">注册</a> */}
                {/* eslint-enable */}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="login_footer">
          <div style={{ display: "inline-block", width: 20, marginRight: 8 }}>
            <Logo type="trans" style={{ width: 20 }} />
          </div>
          格心科技提供技术支持
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);

export default withRouter(connect()(WrappedNormalLoginForm));
