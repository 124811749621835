import {
  SET_VOICE_STATE
} from './action-types';

const initialState = {
  voiceState: 'pause',
  token: '',
  expire: 0
};

const reducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_VOICE_STATE:
      return Object.assign({}, state, {
        voiceState: action.data
      });
    default:
      return state;
  }
};

export { reducer };
