import React from "react";
import ReactDOM from "react-dom";
import { LocaleProvider } from "antd";
import moment from 'moment';
import zh_CN from "antd/lib/locale-provider/zh_CN";
import 'moment/locale/zh-cn';
import App from "./App.jsx";
import "./index.less";

moment.locale('zh-cn');
ReactDOM.render(
  <LocaleProvider locale={zh_CN}>
    <App />
  </LocaleProvider>,
  document.getElementById("root")
);

if(module.hot){
  module.hot.accept();
}
