import {
  SET_PATIENT_LIST_DATA
} from "./action-types";

const intialState = {
  listData: {
    isLoading: false,
    list: []
  }
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_PATIENT_LIST_DATA:
      return { ...state, ...{ listData: action.data } };
    default:
      return state;
  }
};

export { reducer };
