import React from "react";
import propTypes from "prop-types";
import { Button, Icon, Carousel, Radio, Statistic, Divider, Modal, message } from "antd";
import { withRouter } from "react-router-dom";
import Voice from "./Voice2";
import TEST_MODE from "../config/testMode";
import "./TestForm.less";
import http, { DOWNLOAD_PATH } from "../../api/api";
import ReportModal from "../reports/ReportModal";
import { TASK_EXAM_STATE } from "../../api/auth/config";
import NormalIssue from "./components/NormalIssue";
import ImageIssue from "./components/ImageIssue";
import {IMG_BASEURL} from "../../api/api";

class TestForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.scroll = React.createRef();
    console.log("this.props", this.props);
    const { data } = this.props;
    const newData = JSON.parse(JSON.stringify(data));
    newData.exam.desc = newData.exam.desc && JSON.parse(newData.exam.desc);
    newData.exam.waring = newData.exam.warn && JSON.parse(newData.exam.waring);
    newData.exam.subject =
      newData.exam.subject && JSON.parse(newData.exam.subject);
    newData.taskExam.result =
      newData.taskExam.result && JSON.parse(newData.taskExam.result);

    this.state = {
      mode: TEST_MODE.normal,
      ret: newData.taskExam.result || [],
      startTime: newData.taskExam.startTime
        ? new Date(newData.taskExam.startTime)
        : null,
      endTime: newData.taskExam.endTime
        ? new Date(newData.taskExam.endTime)
        : null,
      isStart: false, // 开始答题，用于是否显示进度
      currIndex: 1,
      pointObj: newData.taskExam.point
        ? JSON.parse(newData.taskExam.point)
        : {},
      data: newData,

      reportModalVisible: false,
      taskExamId: null
    };
  }

  showReportModal = taskExam => {
    this.setState({
      reportModalVisible: true,
      taskExamId: taskExam.taskExam.id
    });
  };

  hideReportModal = () => {
    this.setState({
      reportModalVisible: false
    });
  };

  handleScreenChange = () => {
    if (document.fullscreenElement) {
      this.setState({
        mode: TEST_MODE.zen
      });
    } else {
      this.setState({
        mode: TEST_MODE.normal
      });
    }
  };

  toZenMode = () => {
    document.documentElement.requestFullscreen();
  };

  exitZenMode = () => {
    document.fullscreenElement && document.exitFullscreen();
  };

  lightUp = () => {
    if (this.state.mode === TEST_MODE.zen) {
      this.setState({
        mode: TEST_MODE["zen-light"]
      });
    } else {
      this.setState({
        mode: TEST_MODE.zen
      });
    }
  };

  selectRadio = (e, item) => {
    const { value } = e.target;
    const selectItem = item.items.find(item => item.iNum === value);
    const ret = Object.assign({}, this.state.ret, {
      [item.num]: selectItem
    });

    return new Promise((resolve, reject) => {
      this.setState(
        {
          ret
        },
        () => {
          http.updateTaskExam({
            id: this.state.data.taskExam.id,
            result: JSON.stringify(ret)
          })
            .then(() => resolve());
        }
      );
    })
  };

  startTest = () => {
    // 修改 taskExam 的开始时间
    const startFn = () => {
      this.setState(
        {
          startTime: new Date()
        },
        () => {
          http.updateTaskExam({
            id: this.state.data.taskExam.id,
            startTime: this.state.startTime.toISOString(),
            state: 1
          });
          this.scroll.current && this.scroll.current.slick.slickNext();
          setTimeout(() => {
            this.setState(prevState => ({ ...prevState, isStart: true }));
          }, 500);
        }
      );
    };


    console.log(this.state.data.exam.path)
    if (this.state.data.exam.path) {
      Modal.confirm({
        title: "下载附件",
        content: "完成该量表需要下载一些附件（图片或说明文档等），您可以现在下载也可以稍后点击题目框右上角的下载按钮进行下载。",
        okText: "下载",
        onOk: () => {
          var a = document.createElement('a');
          var filename = 'myfile.zip';
          a.href = DOWNLOAD_PATH + this.state.data.exam.path;
          a.download = this.state.data.exam.path;
          a.click();
          startFn();
        },
        onCancel: () => {
          startFn();
        }
      })
    } else {
      startFn()
    }
  };

  prevQuestion = (item, index) => {
    this.scroll.current && this.scroll.current.slick.slickPrev();
    this._setIndex(item.num - 1);
  };

  nextQuestion = (item, index, inputId) => {
    // 填空题必须有答案才行
    if (item.type === "filling") {
      if (this.isFillingEmpty(inputId))  {
        message.warning("请填写答案才能做下一题")
        return;
      } else {
        const ret = Object.assign({}, this.state.ret, {
          [item.num]: {
            content: document.getElementById(inputId).value
          }
        });
        this.setState(
          {
            ret
          },
          () => {
            http.updateTaskExam({
              id: this.state.data.taskExam.id,
              result: JSON.stringify(ret)
            })
          }
        );
      }
    }

    if (index < this.state.data.exam.subject.length) {
      this.scroll.current && this.scroll.current.slick.slickNext();
      window.speechSynthesis.cancel();
      if (index === this.state.data.exam.subject.length - 1) {
        this.setState({
          endTime: new Date()
        });
        return Promise.resolve();
      } else {
        return this._setIndex(item.num + 1);
      }
    } else {
      return Promise.resolve();
    }
  };

  complete = (item, index) => {
    this.nextQuestion(item, index);
    this.setState(
      {
        endTime: new Date()
      },
      () => {
        http
          .updateTaskExam({
            id: this.state.data.taskExam.id,
            endTime: this.state.endTime.toISOString(),
            state: TASK_EXAM_STATE["已完成"]
          })
          .then(() => {
            this.props.complete();
          });
      }
    );
    // TODO 判断是否创建预警

    // 算分
    http.countPoint({ id: this.state.data.taskExam.id }).then(res => {
      const { points } = res.data;
      this.setState({
        pointObj: points
      });
    });
    // const pointObj = eval(this.props.data.getPoint)(this.state.ret);
    // console.log('pointObj', pointObj);
    // this.setState({
    //   pointObj
    // })

    // 判断是否改变 Task 的状态
  };

  onRadioChange = (e, item, index, callBack) => {
    return new Promise((resolve, reject) => {
      this.selectRadio(e, item)
        .then(() => {
          if (index === this.state.data.exam.subject.length - 1) {
            return;
          }
          // clearTimeout(this._nextQuestionTimer);
          // this._nextQuestionTimer = setTimeout(() => {
            this.nextQuestion(item, index)
              .then(() => {
                resolve();
              });
          // }, 50);
        });
    });
  };

  onKeyPress = e => {
    const charCode = e.charCode || e.keyCode;
    const realChar = String.fromCharCode(charCode);
    const validNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (!this.state.isStart) {
      // 欢迎界面，不允许按键
      console.log(this.state.currIndex, "欢迎界面，不允许按键");
      return;
    }

    if (this.state.data.exam.subject[this.state.currIndex - 1].type === 'filling') {
      console.log("填空题不支持按键")
      return;
    }

    if (validNumber.find(item => `${item}` === realChar)) {
      if (this.forbidKeyPress) {
        console.log("避免频繁按键");
        return;
      }

      if (this.state.currIndex > this.state.data.exam.subject.length) {
        // 题已做完
        console.log("题目已经做完");
        this.forbidKeyPress = false;
        return;
      }
      const subject = this.state.data.exam.subject[this.state.currIndex - 1];
      const items = subject.items;
      const itemsLength = items.length;
      if (itemsLength < Number(realChar)) {
        // 按键超出选项
        console.log("按键超出选项");
        return;
      }

      this.forbidKeyPress = true;

      // 按键后的开始时间
      const keyPressStartTime = new Date();

      this.onRadioChange(
        { target: { value: items[Number(realChar) - 1].iNum } },
        subject,
        this.state.currIndex - 1
      )
        .then(() => {
          // 按键后答题更新的时间
          const keyPressEndTime = new Date();
          if ((keyPressEndTime - keyPressStartTime) < 1000) {
            clearTimeout(this._forceKeyPressWaitTimer)
            this._forceKeyPressWaitTimer = setTimeout(() => {
              this.forbidKeyPress = false;
            }, 1000 - (keyPressEndTime - keyPressStartTime))
          } else {
            this.forbidKeyPress = false;
          }
        });

      // setTimeout(() => {
      // }, 850);
    }
  };

  _setIndex = index => {
    return new Promise((resolve, reject) => {
      clearTimeout(this._setIndexTimer);
      this._setIndexTimer = setTimeout(() => {
        this.setState({
          currIndex: index
        }, () => {
          resolve();
        });
      }, 500);
    });
  };

  formatTime(timestamp) {
    console.log("用时", timestamp);
    if (isNaN(timestamp)) return;
    let hours, minutes, seconds, str;
    hours = Math.floor(timestamp / 3600000);
    minutes = Math.floor((timestamp % 3600000) / 60000);
    seconds = Math.floor((timestamp % 60000) / 1000);
    str = "";
    if (seconds) {
      str = ` ${seconds}秒`;
    }
    if (minutes) {
      str = ` ${minutes}分` + str;
    }
    if (hours) {
      str = `${hours}时` + str;
    }

    if (!seconds && !minutes && !hours) {
      return 0 + "秒";
    }
    return str;
  }

  get testWrapperClassName() {
    switch (this.state.mode) {
      case TEST_MODE.normal:
        return "test-wrapper";
      case TEST_MODE.zen:
        return "test-wrapper test-wrapper--fullscreen";
      case TEST_MODE["zen-light"]:
        return "test-wrapper test-wrapper--fullscreen-light";
      default:
        return "test-wrapper";
    }
  }

  get progressWidth() {
    let length = this.state.data.exam.subject.length;
    return Math.floor((this.state.currIndex / length) * 100) + "%";
  }

  isFillingEmpty = (id) => {
    return document.getElementById(id).value ? false : true;
  };

  componentDidMount() {
    document.documentElement.addEventListener(
      "fullscreenchange",
      this.handleScreenChange
    );

    // 判断目前题目在什么状态
    const { state } = this.state.data.taskExam;
    console.log("state", state);
    switch (Number(state)) {
      case TASK_EXAM_STATE["已完成"]:
        this.setState({ isStart: true });
        this._setIndex(Object.keys(this.state.ret).length);
        this.scroll.current.slick.slickGoTo(
          Object.keys(this.state.ret).length + 1
        );
        break;
      case TASK_EXAM_STATE["进行中"]:
        if (Object.keys(this.state.ret).length > 0) {
          this.setState({ isStart: true });
          this._setIndex(Object.keys(this.state.ret).length);
          this.scroll.current.slick.slickGoTo(
            Object.keys(this.state.ret).length
          );
        }
        break;
      default:
        break;
    }
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener(
      "fullscreenchange",
      this.handleScreenChange
    );
  }

  render() {
    return (
      <div
        tabIndex="0"
        className={this.testWrapperClassName}
        onKeyPress={this.onKeyPress}>
        <div className="test-content__wrapper">
          {/* 进度条 */}
          {!this.state.isStart ? null : (
            <div className="test-index__wrapper">
              <div className="test-index_progress">
                <div
                  className="test-index_progress--now"
                  style={{ width: this.progressWidth }}
                />
              </div>
              {/* <Progress strokeLinecap="square" /> */}
              <Statistic
                style={{ textAlign: "center", marginTop: "6px" }}
                value={this.state.currIndex}
                suffix={`/ ${JSON.parse(this.props.data.exam.subject).length}`}
              />

              {/* this.props.data.subject.map((item, index) => {
                const doneClassName = this.state.ret[item.num]
                  ? "test-index__item--done"
                  : "";
                return (
                  <span
                    className={`test-index__item ${doneClassName}`}
                    key={index}
                    onClick={() => {
                      this.scroll.current.slick.slickGoTo(index + 1);
                    }}>
                    {index + 1}
                  </span>
                );
              }) */}

              <Divider />
            </div>
          )}

          <Carousel
            dots={false}
            ref={this.scroll}
            lazyLoad={true}
            infinite={false}
            adaptiveHeight={true}
            accessibility={false}
            arrows={false}
            speed={500}>
            {/* 测试封面 */}
            <div>
              <div className="test-content__welcome">
                <h2 className="title">{this.state.data.exam.bname}</h2>
                {(() => {
                  console.log("thththththt", this.state.data);
                  let desc = this.state.data.exam.desc;
                  return (
                    desc &&
                    desc.map((item, index) => (
                      <div className="content" key={index}>
                        <strong>{item.title}：</strong>
                        {item.content}
                      </div>
                    ))
                  );
                })()}
                <div className="footer">
                  <p className="patient">受测者：{this.props.patient.pname}</p>
                  <Button type="primary" ghost onClick={this.startTest}>
                    开始测试
                  </Button>
                </div>
              </div>
            </div>

            {(() => {
              let subject = this.state.data.exam.subject;
              return (
                subject &&
                subject.map((item, index) => {
                  return (
                    <div key={item.num}>
                      <div className="test-content__item">
                        {(() => {
                          switch (item.type) {
                            case "image":
                              return <ImageIssue item={item} />;
                            default:
                              return <NormalIssue item={item} />;
                          }
                        })()}

                        {(() => {
                          if (item.type === 'filling') {
                            return (
                              <div
                                key={`${item.num}-${item.type}`}
                              >
                                答案：<input
                                  type={item.fillingType || "text"}
                                  style={{border: "none", borderBottom: "1px solid #000", outline: "none"}}
                                  id={`input-${this.state.data.taskExam.id}-${item.num}`}
                                  defaultValue={this.state.ret[item.num] ? this.state.ret[item.num].content : '' }
                                />
                              </div>
                            );
                          }

                          switch (item.items[0].type) {
                            case "image":
                              return (
                                <div
                                  key={`${item.num}-${item.items[0].type}`}
                                  onChange={e =>
                                    this.onRadioChange(e, item, index)
                                  }
                                  className="radio-wrapper image">
                                  {item.items.map((radioItem, index) => {
                                    return (
                                      <div className="radio-item">
                                        <label
                                          for={`${this.state.taskExamId}-${
                                            item.num
                                          }-${radioItem.iNum}`}>
                                          <span>{radioItem.iNum}</span>
                                          <img
                                            src={IMG_BASEURL + radioItem.imageUrl}
                                            alt=""
                                            style={{ width: "100%" }}
                                          />
                                          <span>{radioItem.item}</span>
                                        </label>
                                        <input
                                          id={`${this.state.taskExamId}-${
                                            item.num
                                          }-${radioItem.iNum}`}
                                          type="radio"
                                          value={radioItem.iNum}
                                          checked={
                                            (this.state.ret[item.num] &&
                                              this.state.ret[item.num].iNum) ===
                                            radioItem.iNum
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            default:
                              return (
                                <Radio.Group
                                  key={`${item.num}-${item.items[0].type}`}
                                  onChange={e =>
                                    this.onRadioChange(e, item, index)
                                  }
                                  value={
                                    this.state.ret[item.num]
                                      ? this.state.ret[item.num].iNum
                                      : null
                                  }
                                  className="radio-wrapper">
                                  {item.items.map((radioItem, index) => {
                                    return (
                                      <Radio
                                        className="radio-item"
                                        value={radioItem.iNum}
                                        key={radioItem.iNum + "" + index}>
                                        {`${radioItem.iNum}、${radioItem.item}`}
                                      </Radio>
                                    );
                                  })}
                                </Radio.Group>
                              );
                          }
                        })()}

                        <div style={{ paddingTop: 35 }}>
                          <Button
                            type="primary"
                            onClick={() => this.prevQuestion(item, index)}
                            style={{ marginRight: 16 }}
                            ghost
                            disabled={item.num === 1}>
                            上一题
                          </Button>
                          {(item.type === 'filling') ||
                          (!!this.state.ret[item.num] &&
                            this.state.currIndex !==
                            subject.length) ? (
                            <Button
                              type="primary"
                              onClick={() => this.nextQuestion(item, index, `input-${this.state.data.taskExam.id}-${item.num}`)}>
                              下一题
                            </Button>
                          ) : null}
                          {this.state.currIndex ===
                          subject.length ? (
                            <Button
                              type="primary"
                              onClick={() => this.complete(item, index)}>
                              完成
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              );
            })()}

            {/* 结束页面 */}
            <div className="ret-wrapper">
              <table className="point-table">
                <tbody>
                  <tr>
                    <th className="header" colSpan="2">
                      结果
                    </th>
                  </tr>
                  {this.state.pointObj["总分"] === undefined ? null : (
                    <tr>
                      <th>总分</th>
                      <td>{this.state.pointObj["总分"]}</td>
                    </tr>
                  )}
                  {(() => {
                    const divisorTr = [];
                    for (let key in this.state.pointObj) {
                      if (key === "总分") {
                        continue;
                      } else {
                        divisorTr.push(
                          <tr key={key}>
                            <th>{key}</th>
                            <td>{this.state.pointObj[key]}</td>
                          </tr>
                        );
                      }
                    }
                    return divisorTr;
                  })()}
                </tbody>
              </table>
              <p className="time">
                用时：
                {this.formatTime(this.state.endTime - this.state.startTime)}
              </p>
              <p className="patient">受测者：{this.props.patient.pname}</p>
              <div style={{ margin: "15px  0" }}>
                <Button
                  type="primary"
                  onClick={() => this.showReportModal(this.props.data)}>
                  查看详细报告
                </Button>
              </div>
              <div className="ret-card">
                {this.state.data.exam.subject &&
                  this.state.data.exam.subject.map(item => {
                    const { num, issue } = item;
                    const answer = this.state.ret[num];
                    if (answer) {
                      return (
                        <div key={num}>
                          <h5 className="question">
                            {num}、{issue}
                          </h5>
                          <p className="answer">
                            <Icon
                              color="success"
                              type="check-square"
                              style={{ marginRight: 8 }}
                            />
                            {answer.iNum}、{answer.item}
                          </p>
                        </div>
                      );
                    } else {
                      return <div key={num} />;
                    }
                  })}
              </div>
            </div>
          </Carousel>
          {this.state.reportModalVisible ? (
            <ReportModal
              taskId={this.props.taskId}
              taskExams={this.props.taskExams}
              examId={this.props.data.exam.id}
              taskExamId={this.state.taskExamId} // 标明当前要查看哪个 taskExam
              visible={this.state.reportModalVisible}
              closeModal={this.hideReportModal}
            />
          ) : null}

          {/*this.state.mode === TEST_MODE.normal && (
            <div className="nofullscreen-control-wrapper">
              <div
                style={{ width: 50, lineHeight: "50px", textAlign: "center" }}
                title="沉浸模式"
                onClick={this.toZenMode}>
                <Icon type="bulb" theme="filled" />
              </div>
            </div>
          )*/}

          {
            this.state.data.exam.path &&
            <div className="nofullscreen-control-wrapper">
              <a
                href={DOWNLOAD_PATH + this.state.data.exam.path}
                target="_blank" download={this.state.data.exam.path}
                style={{display: "block", width: 50, lineHeight: "50px", textAlign: "center" }}
                title="下载文件">
                  <Icon type="download" />
              </a>
            </div>
          }
        </div>

        {this.state.mode !== TEST_MODE.normal && (
          <div
            style={{
              position: "absolute",
              bottom: 50,
              right: 80,
              transform: "background 1s ease-in-out",
              border:
                this.state.mode === TEST_MODE.zen ? "" : "1px solid #e8e8e8",
              borderRadius: "3px",
              cursor: "pointer",
              background: this.state.mode === TEST_MODE.zen ? "#111" : "#fff",
              color: "#666"
            }}>
            <div
              style={{
                display: "inline-block",
                width: 50,
                lineHeight: "50px",
                textAlign: "center"
              }}
              title={this.state.mode === TEST_MODE.zen ? "开灯" : "关灯"}
              onClick={this.lightUp}>
              <Icon
                type="bulb"
                theme={
                  this.state.mode === TEST_MODE.zen ? "outlined" : "filled"
                }
              />
            </div>
            <div
              style={{
                display: "inline-block",
                width: 50,
                lineHeight: "50px",
                textAlign: "center"
              }}
              title="退出全屏"
              onClick={this.exitZenMode}>
              <Icon type="fullscreen-exit" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

TestForm.propTypes = {
  data: propTypes.object
};

export default withRouter(TestForm);
