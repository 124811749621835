import React from "react";
import { IMG_BASEURL } from "../../../api/api";

function ImageIssue(props) {
  const { item } = props;
  console.log("ImageIssue", item);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        alignContent: "flex-start",
        marginBottom: 20
      }}>
      <h3 className="title" style={{padding: 0}}>{item.num + "、" + item.issue}</h3>
      <img src={IMG_BASEURL + item.imageUrl} alt="" style={{maxWidth: "100%"}}/>
    </div>
  );
}

export default ImageIssue;
