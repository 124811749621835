import React from "react";
import {Icon} from "antd";

function Fallback(props) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
    <div><Icon type="loading" color="primary" style={{fontSize: 32}} /></div>
      <div style={{marginTop: 16, fontSize: 22}}>页面加载中...</div>
    </div>
  );
}

export default Fallback;
