import {
  TOKEN_NAME,
  EXPIRE_NAME,
  USER_INFO_NAME
} from "./config";

export default () => {
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(EXPIRE_NAME);
  localStorage.removeItem(USER_INFO_NAME);
}
