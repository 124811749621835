import {call, put, takeEvery} from "redux-saga/effects";

const axios = () => {};

function *fetchCustomerList() {
  const customerList = yield call(axios, {method: 'POST', url: 'dd', data: {}});
  put({type: 'ACTION', data: {}})
}


export default function* () {
  yield takeEvery("ASYNC_FETCH_CUSTOMER", fetchCustomerList);
}
