import {
  SET_VOICE_STATE
} from './action-types';

export function setVoiceState (state) {
  return {
    type: SET_VOICE_STATE,
    state
  }
}
