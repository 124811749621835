import React from "react";
import { Modal, Select, Divider, DatePicker, Spin, message } from "antd";
import http from "../../api/api";

const { Option } = Select;

class ReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: 0,
      taskExamId: 0,
      examId: 0
    };
    this.frameRef = React.createRef();
  }

  changeReport = value => {
    this.setState({
      taskExamId: value
    });
  };

  setIFrameHeight = e => {
    e.currentTarget.height = e.currentTarget.contentDocument.body.scrollHeight;
    this.setIFrameHeightTimer = setTimeout(() => {
      if (this.frameRef.current) {
        this.frameRef.current.height =
          this.frameRef.current.contentWindow.document.body.scrollHeight;
      }
    }, 1200);
  };

  getExamId = () => {
    let { taskExams, taskExamId } = this.props;
    taskExamId = this.state.taskExamId || taskExamId;
    let examId;
    taskExams.forEach(item => {
      if (+item.taskExam.id === +taskExamId) {
        examId = item.exam.id;
      }
    });
    console.log('examid', examId)
    return examId;
  };

  changeStartTime = (moment) => {
    const taskExamId = this.state.taskExamId || this.props.taskExamId;
    if (taskExamId) {
      http.findTaskExamById(taskExamId)
        .then(res => {
          const date = res.data;
          if (date.result === 0) {
            const startTime = new Date(date.taskExam.startTime);
            const endTime = new Date(date.taskExam.endTime);
            const newStartTime = moment._d;
            const newEndTime = new Date(
              endTime.getTime() + (newStartTime.getTime() - startTime.getTime())
            );
            const hideMsgLoading = message.loading("修改中...", 0);
            http.updateTaskExam({
              id: taskExamId,
              startTime: newStartTime.toISOString(),
              endTime: newEndTime.toISOString()
            })
              .then(() => {
                hideMsgLoading()
                message.success("修改成功！");
                document.getElementsByName("iFrame1")[0].contentWindow.location.reload();
              })
              .catch(() => {
                hideMsgLoading();
                message.error("修改失败！");
              })
          }
        })
    }
  };

  componentWillUnmount() {
    clearTimeout(this.setIFrameHeightTimer);
  }

  render() {
    console.log("?????", this.props);
    let { taskId, taskExams, taskExamId } = this.props;
    if (!taskExamId && taskExams) {
      taskExams.every(item => {
        if (Number(item.taskExam.state) === 2) {
          // 表示该量表已完成
          taskExamId = item.taskExam.id;
          return false; // 停止循环
        } else {
          return true;
        }
      });
    }
    return (
      <Modal
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        title={"结果报告"}
        bodyStyle={{
          padding: 0
        }}
        width={794}
        footer={null}>
        <div style={{ marginTop: 24, padding: "0 24px" }}>
          选择量表：
          <Select
            placeholder="请选择量表"
            defaultValue={this.state.taskExamId || taskExamId}
            style={{ minWidth: 250, marginRight: 25 }}
            onChange={this.changeReport}>
            {this.props.taskExams &&
              this.props.taskExams.map(item => {
                return (
                  <Option
                    key={item.taskExam.id}
                    value={item.taskExam.id}
                    disabled={+item.taskExam.state !== 2}>
                    {item.exam.bname}
                  </Option>
                );
              })}
          </Select>
          修改测试时间：
          {/* <Spin spinning={this.state.isChangeStartTime} style={{display: "inline-block"}}> */}
            <DatePicker
              showTime
              placeholder="修改时间"
              onOk={this.changeStartTime}
            />
          {/* </Spin> */}
        </div>
        <Divider style={{ marginBottom: 0 }} />
        {taskId && this.getExamId() && (
          <iframe
            ref={this.frameRef}
            title="liangbiao"
            src={`/report/${taskId}/${this.state.taskExamId || taskExamId}/${
              this.getExamId()
            }`}
            width={"100%"}
            name={"iFrame1"}
            onLoad={this.setIFrameHeight}
            frameBorder="0"
          />
        )}
      </Modal>
    );
  }
}

export default ReportModal;
