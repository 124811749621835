import React, { PureComponent, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import { TOKEN_NAME } from "./api/auth/config";
import isExpire from "./api/auth/isExpire";
import reduxData from "./store";
import Login from "./pages/Login.jsx";
import Test from "./pages/test/Test";
import Fallback from "./pages/Fallback";

import { Modal } from "antd";
import ReportController from "./pages/reports/ReportController";

const AdminHomePage = lazy(() => import("./ROLE/admin/Admin"));
const DoctorHomePage = lazy(() => import("./ROLE/doctor/Doctor"));
const PatientHomePage = lazy(() => import("./ROLE/patient/Patient"));

const { store } = reduxData();

class App extends PureComponent {
  render() {
    console.log(this.props);
    const Main = withRouter(props => {
      const { pathname } = props.location;
      const token = localStorage.getItem(TOKEN_NAME);
      if ((!token || isExpire()) && pathname !== "/login") {

        // 如果没有 token，直接跳到登录页
        if (!token) {
          props.history.push('/login');
          return null;
        }

        console.log('token', token, 'isExpire', isExpire())
        Modal.info({
          content: (
            <div>
              <p>你未登录或登录凭证过期，请重新登录。</p>
            </div>
          ),
          onOk() {
            props.history.push('/login');
          }
        });
        return null;
      } else {
        return (
          <Switch>
            <Route
              path="/report/:taskId/:taskExamId/:examId"
              component={ReportController}
            />
            <Route
              path="/admin"
              render={props => <AdminHomePage {...props} />}
            />
            <Route
              path="/doctor"
              render={props => <DoctorHomePage {...props} />}
            />
            <Route
              path="/patient"
              render={props => <PatientHomePage {...props} />}
            />
            <Route exact path="/test/:id" component={Test} />
            <Route exact path="/login" component={Login} />
            <Redirect exact from="/" to="/login" />
            <Route render={() => <div>404 NOT FOUND</div>} />
          </Switch>
        );
      }
    });
    return (
      <Router>
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          <Suspense fallback={<Fallback />}>
            <Main />
            {/* <Switch>
              <Route
                path="/admin"
                render={props => <AdminHomePage {...props} />}
              />
              <Route
                path="/doctor"
                render={props => <DoctorHomePage {...props} />}
              />
              <Route
                path="/patient"
                render={props => <PatientHomePage {...props} />}
              />
              <Route exact path="/test/:id" component={Test} />
              <Route exact path="/login" component={Login} />
              <Redirect exact from="/" to="/login" />
              <Route render={() => <div>404 NOT FOUND</div>} />
            </Switch> */}
          </Suspense>
          {/* </PersistGate> */}
        </Provider>
      </Router>
    );
  }
}

export default App;
